import React from 'react'
import { Helmet } from 'react-helmet'
import { Container, Row, Col } from 'reactstrap'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <Container fluid>
      <Helmet title="whoops" />
      <Row noGutters className="text-center">
        <Col>sorry, we couldn't find that page</Col>
      </Row>
    </Container>
  </Layout>
)

export default NotFoundPage
